<template>
  <div class="main">
    <banner-swiper class="other">
      <swiper-slide>
        <div class="banner-cover" />
        <div class="banner-desc other">
          <div class="icon" />
          <div class="title">广告投放</div>
          <div class="text">投放精准 转化高效<br>让广告客户获得品效合一的转化投放效果</div>
        </div>
      </swiper-slide>
      <div slot="after" class="english-run">FEYSOS</div>
    </banner-swiper>
    <ul class="introduction-list wrap">
      <li v-scroll-toggle="['enter']">
        <div class="cover">
          <div class="img-scale">
            <img src="@/assets/introduction_advert_01.jpg" alt="">
          </div>
        </div>
        <div class="desc">
          <div class="cn">精准投放 高效转化</div>
          <div class="en">Precise delivery and efficient transformation</div>
          <p>用户主动选择广告，结合投放定向条件，为您带来难以置信的广告效果。<br>定向条件除了时段、地域、系统、浏览器等，还包含独立平台特色的丰富媒体属性定向。</p>
        </div>
      </li>
      <li v-scroll-toggle="['enter']">
        <div class="cover">
          <div class="img-scale">
            <img src="@/assets/introduction_advert_02.jpg" alt="">
          </div>
        </div>
        <div class="desc">
          <div class="cn">易操作的自助广告管理</div>
          <div class="en">Easy to operate advertising management</div>
          <p>简单创建广告、轻松管理预算、自由出价、随时投放暂停广告和定时投放等。</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import BannerSwiper from '@/components/bannerSwiper'

import scrollToggle from '@/directives/scrollToggle'

export default {
  name: 'Advert',
  components: {
    BannerSwiper
  },
  directives: {
    scrollToggle
  }
}
</script>

<style lang="scss" scoped>
.banner-container{
  .banner-cover{
    transform-origin: 50% 0%;
    background-position: 50% 20%;
    background-image: url('../../assets/banner_advert.jpg');
  }
}
</style>
